mat-bottom-sheet-container {
  padding-top: 0 !important;
  width: 80vw;
  height: 80vh;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 0px solid transparent;
    margin-left: 10px;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label.mat-tab-disabled {
      opacity: 1;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label {
      border-bottom: 3px solid var(--gematik-blue);
      color: var(--gematik-blue);
      font-weight: bold;
      margin-left: 5px !important;
      margin-right: 5px !important;
      font-size: 16px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-active {
      opacity: 1;
      border-bottom: 3px solid transparent;
      color: var(--gematik-blue);
      font-weight: bold;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

    .mat-ink-bar {
      background: var(--gematik-neon) !important;
      height: 3px;
    }
  }
}

.mat-mdc-form-field-error {
  color: var(--gematik-error) !important;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: var(--gematik-error) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #00000099 !important;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: var(--gematik-error) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--gematik-blue);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-checked {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle {
    border-width: 5px !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-form-field .mat-mdc-select.mat-select-invalid .mat-select-arrow {
  color: var(--gematik-error) !important;
}

.mat-mdc-option {
  line-height: 1.5em !important;
  padding: 7px !important;
  min-height: inherit !important;
}

mat-option.mat-mdc-option {
  height: unset !important;
}

.mat-option-text {
  white-space: normal !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--gematik-blue) !important;
}

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: transparent !important;
// }

mat-card {
  border-radius: 8px !important;
  box-shadow: var(--mdc-elevated-card-container-elevation) !important;
  mat-card-header {
    mat-card-title {
      color: var(--gematik-blue);
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  mat-card-content {
    @extend .mt-2;
  }
}

.mat-badge-medium .mat-badge-content {
  margin: 0px !important;
}

mat-expansion-panel {
  mat-expansion-panel-header {
    mat-panel-title {
      color: var(--gematik-blue) !important;
    }
  }
}

@media (max-width: 430px) {
  .mat-table-responsive-container {
    width: 100% !important;
  }
  .mat-mdc-table[matTableResponsive] {
    .mat-mdc-header-row {
      display: flex;
      flex-wrap: wrap;
      // height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-mdc-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;

        & > .mat-sort-header-container {
          padding-left: 15px;
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
        display: none;
      }
    }

    .mat-mdc-row {
      height: auto;

      td.mat-mdc-cell:not(.mat-column-actions) {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        text-align: right;
        word-break: break-word;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }
      }

      td.mat-column-actions {
        display: flex;
        justify-content: center;
        padding: 0.5rem 0.5rem;

        & > button {
          margin: 0.5rem 0;
        }
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      &:not(:last-of-type) td:last-of-type {
        border-bottom: 0.5rem solid #fafafa;
      }
    }
  }

  .mat-mdc-card,
  .mat-mdc-expansion-panel {
    .mat-mdc-table[matTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }
}
