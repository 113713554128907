.highlight-overlay {
  background-color: #007700; /* color elements as green */
  opacity: 0.4;
  pointer-events: none; /* no pointer events, allows clicking through onto the element */
  border-radius: 10px;
}

.measures-dialog-style {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    width: 1200px;
  }
}

.angular-editor-wrapper {
  text-align: left;
}

.bottom-align {
  position: absolute;
  bottom: 0;
}

.anzeigename {
  font-size: 10px;
}

body {
  overflow-wrap: break-word;
  margin: 0;
  font-family: "Apercu", "Euclid Circular B";
  height: 100%;
  font-size: 18px;
  // background: #fafafa;

  hr.sl-entry-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  hr.ci-seperator {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .container {
    padding-top: 30px;
  }

  .container-fluid {
    margin-top: 80px;
    padding-top: 20px;
    position: relative;
    padding-left: 60px;
    // height: 93vh;
  }

  @-moz-document url-prefix() {
    .container-fluid {
      height: initial;
    }
  }

  @media only screen and (max-width: 576px) {
    .col-12 {
      padding: 0 45px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--on-secondary);
  }

  h1 {
    font-size: 2.7em;
    font-weight: bold;
  }

  h2 {
    font-size: 2.2em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.7em;
    font-weight: bold;
  }

  h4 {
    font-size: 1.2em;
    font-weight: bold;
  }

  h5 {
    font-size: 1em;
  }

  .btn-primary {
    background: var(--gematik-blue);
    color: var(--gematik-neon) !important;
    border-color: white;
    border-width: 2px;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }
  .btn-primary:disabled,
  .btn-primary.disabled,
  .btn-primary[disabled] {
    opacity: 30%;
    cursor: not-allowed;
  }

  .btn-primary:focus,
  .btn-primary:hover {
    background: var(--gematik-blue);
    color: var(--gematik-neon);
    border-color: var(--gematik-neon);
    border-width: 2px;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }

  .btn-primary:disabled {
    color: var(--gematik-neon) !important;
    background-color: var(--gematik-blue);
    border-color: var(--gematik-blue);
  }

  .btn-secondary {
    background: var(--white);
    border-color: var(--gematik-blue);
    border-width: 2px;
    color: var(--gematik-blue);
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }

  .btn-tertiary {
    color: var(--gematik-blue);
  }

  .btn-tertiary:focus,
  .btn-tertiary:hover {
    color: var(--gematik-blue);
    font-weight: bold;
  }

  .btn-add-entity {
    background: var(--gematik-blue);
    color: var(--gematik-neon);
    border-color: white;
    border-width: 2px;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }

  .btn-secondary:focus,
  .btn-secondary:hover {
    background: var(--white);
    border-color: var(--gematik-neon);
    border-width: 2px;
    color: var(--gematik-blue);
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }

  .btn-primary-neon {
    background: var(--gematik-neon);
    color: var(--gematik-blue) !important;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 28px;
    min-width: 100px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    &:hover {
      background: var(--gematik-neon);
      color: var(--gematik-blue) !important;
      font-weight: bold;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 28px;
      min-width: 100px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .btn-secondary-white {
    background: white;
    color: var(--gematik-blue) !important;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 28px;
    min-width: 100px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    &:hover {
      background: white;
      color: var(--gematik-blue) !important;
      font-weight: bold;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 28px;
      min-width: 100px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .btn-error {
    background: var(--gematik-error);
    color: white !important;
    border-color: white;
    border-width: 2px;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }
  .btn-error:focus,
  .btn-error:hover {
    background: var(--gematik-error);
    color: white;
    border-color: var(--gematik-neon);
    border-width: 2px;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 28px;
    min-width: 100px;
  }

  .btn-error:disabled {
    background-color: var(--gematik-error);
  }

  .row {
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }

  a {
    cursor: pointer;
  }

  mat-divider {
    position: relative !important;
    margin: 10px 0 10px 0 !important;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-dialog-title {
    text-align: center;
    font-family: "Apercu", "Euclid Circular B";
  }

  .mat-mdc-dialog-actions {
    display: block;
    text-align: center;
  }

  .mat-mdc-menu-panel {
    .mat-mdc-menu-content {
      padding-top: 8px;
      padding-bottom: 4px;
    }
  }

  .contextMenu {
    min-height: unset;
    .mat-mdc-menu-content {
      padding: 0;
    }
  }

  table {
    width: 100%;

    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow: hidden;

    tr.mat-mdc-header-row {
      background: var(--gematik-blue);

      th.mat-mdc-header-cell {
        color: #fff;
        // font-weight: bold;
        font-size: larger;
      }
    }

    tr.mat-mdc-row {
      // vertical-align: top;
      color: var(--primary);
    }

    // tr.mat-mdc-row:nth-child(even) {
    //   background-color: #fff;
    // }

    // tr.mat-mdc-row:nth-child(odd) {
    //   background-color: #f4f4f5;
    // }

    tr.mat-mdc-row:hover {
      background: var(--secondary);
      background-color: #ebeff5 !important;
    }

    td.mat-mdc-cell {
      padding-top: 5px;
      padding-bottom: 5px;
      //font-size: 0.8em;
      // border-bottom-color: #ebeff5;
    }

    td.actions-col {
      vertical-align: middle;
      text-align: right;
    }

    td.confirm-col {
      text-align: right;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-table {
    width: 100%;

    mat-header-row {
      background: #fff;

      mat-header-cell {
        color: var(--primary) !important;
        font-weight: bold !important;
        font-size: larger !important;
      }
    }

    mat-row {
      vertical-align: top !important;
      color: var(--primary) !important;
    }

    mat-row:nth-child(even) {
      background-color: #fff !important;
    }

    mat-row:hover {
      background: var(--secondary) !important;
    }

    actions-col {
      vertical-align: middle !important;
      text-align: right !important;
    }

    confirm-col {
      text-align: right !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }

  .mat-sort-header-arrow {
    color: #fff;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
  mat-paginator {
    color: var(--primary);
    background-color: #f9f9f9 !important;
    padding: 1.5rem 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  i {
    vertical-align: bottom;
  }

  .mat-mdc-snack-bar-container {
    padding: 0;
    min-height: 0;
  }

  slideshow {
    .arrow {
      border-color: var(--secondary) !important;
      width: 25px !important;
      height: 25px !important;
    }
  }

  .mat-calendar-body-cell-content:hover {
    background-color: var(--secondary) !important;
  }

  .mat-calendar-body-selected {
    background-color: var(--primary);
  }

  .mat-calendar-table-header {
    color: var(--primary);
  }

  .mat-calendar-period-button:hover {
    background-color: var(--secondary);
  }

  ngx-material-timepicker {
    .timepicker__header {
      background: var(--primary) !important;
    }

    .clock-face__number > span.active {
      background: var(--primary) !important;
    }

    .clock-face__clock-hand {
      background: var(--primary) !important;
    }

    .clock-face__number--outer > span {
      color: var(--primary) !important;
    }

    .clock-face__number--outer > span.active {
      color: #fff !important;
    }

    .timepicker__actions {
      div:first-child {
        ngx-material-timepicker-button {
          .timepicker-button {
            color: var(--hover) !important;
          }
        }
      }

      div:last-child {
        ngx-material-timepicker-button {
          .timepicker-button {
            color: var(--primary) !important;
          }
        }
      }
    }
  }

  ngx-material-timepicker-toggle {
    float: right;

    .ngx-material-timepicker-toggle {
      height: 18px;
      padding-top: 0 !important;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: var(--hover);
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 16px;
    height: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-label-content {
    padding-left: 0;

    label {
      margin-top: 9px;
    }
  }
}

ag-grid-angular {
  .ag-header-cell,
  .ag-cell {
    padding-left: 3px !important;
    padding-right: 0px !important;
    line-height: 30px !important;
    font-size: 10px;
  }

  .ag-paging-panel {
    width: 100%;
    background: var(--primary);
    color: #fff !important;

    .ag-icon {
      color: #fff;
    }

    .ag-paging-button.ag-disabled .ag-icon {
      color: var(--hover) !important;
    }
  }

  .ag-cell {
    border-right: 1px solid #e2e2e2 !important;
  }

  .ag-header-cell {
    background: var(--primary) !important;
    color: #fff !important;
  }

  .ag-cell-inline-editing {
    min-width: 200px;
  }

  .ag-cell-focus {
    border: 1px solid #3f51b5 !important;
  }

  .ag-overlay-panel {
    margin-top: 15px !important;
  }
}

.grid-spacer {
  border-bottom: solid 2px var(--primary) !important;
}

.grid-berlin-marker {
  background: #eef !important;
}

.grid-button {
  padding: 3px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: 23px;
  position: absolute;
  top: 1px;

  i {
    position: relative;
    top: -2px;
    font-size: 18px !important;
    line-height: unset;
  }
}

pip-confirmation-dialog {
  p {
    text-align: center !important;
  }
}

.ag-icon-menu {
  color: #fff !important;
}

.ag-icon-filter {
  color: var(--secondary) !important;
}

.cal-month-view {
  .cal-day-number {
    margin-bottom: 0;
    float: none;
    position: relative;
    top: 10px;
    right: -75px;
    font-size: 1em !important;
  }

  .cal-today {
    color: #000 !important;
    font-weight: bold !important;
  }

  .cal-open-day-events {
    background-color: var(--primary) !important;

    .cal-event-action {
      color: var(--secondary);
    }
  }

  .nv-event {
    color: #ad2121;
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
  }

  .av-event {
    font-size: 12px;
    text-align: center;
    padding-top: 10px;

    div {
      text-align: center;
    }
  }

  .ev-event {
    color: var(--secondary);
    text-align: center;
    font-size: 12px;
  }

  .cal-out-month {
    .custom-month-cell {
      opacity: 0.2;

      .cal-day-number {
        opacity: 1;
      }
    }
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.zero-margin {
  margin-left: 0;
  margin-right: 0;
}

.top-margin-nine {
  margin-top: 9px;
}

.mat-mdc-simple-snack-bar {
  padding: 7px 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.pip-mat-border-padding-fix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  border-top: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0.4375em;
  padding-left: 0px;
}

.replaceSelect {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow {
    opacity: 0;
  }
}

.PersonalisedMessage {
  background-color: #d3d3d3 !important;
}

.visually-hidden {
  display: none;
}

svg {
  vertical-align: baseline !important;
}

.vr {
  width: 1px;
  background: #868e96;
}

.background-grey {
  background: var(--gematik-grey);
}

.max-width-1000 {
  max-width: 1000px;
}

.max-width-1400 {
  max-width: 1400px;
}

.gib-flex-center {
  display: flex;
  justify-content: center;
}

.gem-box {
  border-width: var(--mdc-outlined-card-outline-width);
  border-style: solid;
  border-color: var(--mdc-outlined-card-outline-color);
  border-radius: var(--mdc-outlined-card-container-shape);
  padding: 16px;
}

.gem-feedback {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}

// Custom Tooltip
.gem-tooltip {
  position: absolute;
  font-size: inherit;
}

.gem-tooltip::after {
  position: absolute;
  width: 12em;
  background-color: rgba(0, 0, 0, 1);
  content: attr(data-tip);
  padding: 0.6em 0.6em;
  color: #ffffff;
  margin: auto;
  left: -4.5em;
  right: 0;
  bottom: 1.5em;
  text-align: center;
  border-radius: 0.2em;
}

.gem-tooltip::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-top: 0.5em solid rgba(0, 0, 0, 1);
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1em;
}

.gem-tooltip:before,
.gem-tooltip:after {
  display: none;
}

.gem-tooltip:hover:before,
.gem-tooltip:hover:after {
  display: block;
}

.success-snackbar {
  --mdc-snackbar-container-color: var(--gematik-neon);
  --mdc-snackbar-supporting-text-color: var(--gematik-blue);
}

.warning-snackbar {
  --mdc-snackbar-container-color: var(--gematik-warn);
  --mdc-snackbar-supporting-text-color: var(--gematik-blue);
}

.error-snackbar {
  --mdc-snackbar-container-color: var(--gematik-error);
  --mdc-snackbar-supporting-text-color: white;
}

.mat-table-responsive-container {
  width: fit-content;
}

.color-success {
  color: var(--gematik-neon);
}

.color-error {
  color: var(--gematik-error);
}

.gem-color-blue {
  color: var(--gematik-blue) !important;
}

.gem-color-red-medium {
  color: var(--gematik-red-medium);
}

.gem-color-neon {
  color: var(--gematik-neon);
}

.gem-link-blue {
  text-decoration: none;
  color: var(--gematik-blue);
  &__active {
    font-weight: bold;
  }
}

.gem-link-dark-grey {
  text-decoration: none;
  color: var(--gematik-dark-grey) !important;
  &__active {
    font-weight: bold;
  }
}

.gem-link-dark-grey:hover {
  color: var(--gematik-blue) !important;
}

.gem-bg-color-light-grey {
  background-color: var(--gematik-light-grey);
}

.gem-color-dark-grey {
  color: var(--gematik-dark-grey) !important;
}

.gem-mat-card-full-height {
  width: 100%;
  height: 100%;
  align-items: space-between;
  justify-content: stretch;
}

.height-100 {
  height: 100% !important;
}

.mat-card-landing-page {
  border-radius: 10px !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1) !important;
}

.mt-6 {
  margin-top: 3.33rem !important;
}

.form-check-input:checked {
  background-color: var(--gematik-blue) !important;
  border-color: var(--gematik-blue) !important;
}
